<template>
  <div id="app">
    <Header />
    <hr>
    <Payments />
    <hr>
    <Games />
    <Footer />
  </div>
</template>

<script>
import * as components from '@/components/index';
export default {
  name: 'App',
  components: {
    ...components,
  },
};
</script>

<style lang="scss"></style>
