<template>
  <header class="header">
    <div class="header-logo">
      <img
        src="@/assets/img/logo.svg"
        alt="logo"
      >
    </div>
    <hr>
    <div class="header-wrapper">
      <HeaderContent />
    </div>
    <img
      src="@/assets/img/header/begambling.svg"
      alt="be gambling"
      class="header-icon"
    >
  </header>
</template>

<script>
import HeaderContent from '@/components/Header/HeaderContent';

export default {
  name: 'Header',
  components: {
    HeaderContent,
  },
};
</script>

<style lang="scss">
.header {
  position: relative;

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0 12px;

    @media (min-width: $screen-m) {
      padding: 40px 0 21px;
    }

    > img {
      width: 96px;

      @media (min-width: $screen-m) {
        width: initial;
      }
    }

  }

  &-wrapper {
    padding: 154px 0 34px;
    background-image: url(@/assets/img/header/header-bg-mob.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-resolution: 2dppx) {
      background-image: url(@/assets/img/header/header-bg-mob@2x.jpg);
    }

    @media (min-width: $screen-m) {
      padding: 112px 0;
      background-image: url(@/assets/img/header/header-bg.jpg);
      background-position: center bottom;

      @media (min-resolution: 2dppx) {
        background-image: url(@/assets/img/header/header-bg@2x.jpg);
      }
    }
  }

  &-icon {
    position: absolute;
    top: 151px;
    right: 8px;
    width: 129px;

    @media (min-width: $screen-m) {
      top: initial;
      right: 19px;
      bottom: 16px;
      width: initial;
    }
  }
}
</style>
