<template>
  <div class="header-content">
    <p class="header-content-above-title">
      Exclusive Welcome Offer <br>
      for New Players
    </p>
    <div class="header-content-title">
      <p>
        100% <span class="text__colored">bonus</span> up to
      </p>
      <h1>
        <span class="text__colored">£50</span> + <span class="text__colored">100 Spins</span>
      </h1>
    </div>
    <Button />
    <p class="header-content-text">
      18+. New Players Only. Min. deposit £20. Max bonus allowed
      is £50. Offer duration upon receiving is valid for 2 weeks (14 days).
      Max Win from spins is £100. 35x wagering applies both for bonus and spins part. Spins are credited on specific
      games(Starburst). Spins expire after
      24 hours if unused. Full T&Cs Apply. Live games do not contribute
      towards wager requirements.
    </p>
  </div>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'HeaderContent',
  components: {
    Button,
  },
};
</script>

<style lang="scss">
.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  text-align: center;

  @media (min-width: $screen-m) {
    max-width: 469px;
  }

  > *:not(:last-child) {
    margin-bottom: 10px;

    @media (min-width: $screen-m) {
      margin-bottom: 26px;
    }
  }

  &-above-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    text-transform: capitalize;
    color: var(--color-text-white);

    @media (min-width: $screen-m) {
      font-size: 34px;
    }
  }

  &-title {
    font-family: 'Roboto Slab', monospace;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    color: var(--color-text-white);
    text-transform: uppercase;

    @media (min-width: $screen-m) {
      font-size: 40px;
    }

    > h1 {
      font-size: 29px;
      line-height: 1.31;

      @media (min-width: $screen-m) {
        font-size: 58px;
      }
    }
  }

  &-text {
    padding: 0 20px;
    font-size: 10px;
    line-height: 1.4;

    @media (min-width: $screen-m) {
      font-size: 12px;
    }
  }
}
</style>
