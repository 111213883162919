<template>
  <a
    :href="buttonLink"
    target="_blank"
    class="btn"
  >
    Play here
  </a>
</template>

<script>
export default {
  name: 'Button',
  data: () => ({
    urlParams: '',
  }),
  computed: {
    buttonLink () {
      if (!this.urlParams) {
        return 'https://heyspin.com/?NeoDL=Registration';
      }
      return `https://heyspin.com/?NeoDL=Registration${this.urlParams}`;
    },
  },
  created () {
    const url = new URLSearchParams(window.location.search);

    if (url.size > 0) {
      const params = [];

      for (const [key, value] of url) {
        params.push(`${key}=${value}`);
      }

      this.urlParams = params.length ? `&${params.join('&')}` : '';
    }
  },
};
</script>

<style lang="scss">
.btn {
  padding: 16px 62px;
  font-family: 'Roboto Slab', monospace;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--color-text-dark);
  background: var(--color-btn-default);
  box-shadow: 0px 15.6571px 31.3143px rgba(228, 88, 9, 0.24);
  border-radius: 6px;

  &:active {
    background: var(--color-btn-pressed);
  }

  @media (min-width: $screen-m) {
    padding: 32px 80px;
    font-size: 24px;
    line-height: 1;

    &:hover {
      background: var(--color-btn-hover);
    }
  }
}
</style>
