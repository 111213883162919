<template>
  <section class="wrapper games">
    <h2 class="games-title">
      Explore <span class="text__colored">HeySpin's Live</span> Games
    </h2>
    <p class="games-text">
      Including Blackjack, Roulette, Baccarat, Live Poker & So Much More!
    </p>
    <div class="games-list">
      <GamesCard
        v-for="(card, i) in cards"
        :key="i"
        :src="card.src"
        :title="card.title"
        :text="card.text"
      />
    </div>
    <Button />
  </section>
</template>

<script>
import Button from '@/components/Button';
import GamesCard from '@/components/Games/GamesCard';

export default {
  name: 'Games',
  components: { GamesCard, Button },
  data: () => ({
    cards: [
      {
        src: 'card1',
        title: 'Roulette games',
        text: 'Can you guess where the Live Roulette ball will stop? Choose your numbers. Place your bets.',
      },
      {
        src: 'card2',
        title: 'Baccarat Games',
        text: 'Face the dealer as you wait to see who comes out on top. Bet on the dealer, player, or a tie to win.',
      },
      {
        src: 'card3',
        title: 'Blackjack',
        text: 'Take part in perhaps the most fun betting events of all. Be entertained by the vibrant game shows and place your bets to win.',
      },
    ],
  }),
};
</script>

<style lang="scss">
.games {
  text-align: center;

  @media (min-width: $screen-m) {
    text-align: left;
  }

  &.wrapper {
    padding: 26px 16px 40px;

    @media (min-width: $screen-m) {
      padding: 90px 16px;
    }
  }

  &-title {
    margin-bottom: 20px;
    font-family: 'Roboto Slab', monospace;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--color-text-white);

    @media (min-width: $screen-m) {
      margin-bottom: 10px;
      font-size: 46px;
    }
  }

  &-text {
    margin-bottom: 29px;
    font-size: 12px;
    line-height: 1.4;
    color: var(--color-text-ghost);

    @media (min-width: $screen-m) {
      margin-bottom: 80px;
      font-size: 16px;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    @media (min-width: $screen-m) {
      gap: 35px;
      margin-bottom: 40px;
    }

    @media (min-width: $screen-xl) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

  }

  .btn {
    @media (min-width: $screen-m) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
